import React, { useEffect } from 'react'
import Wavy from './Wavy'
import Second from './Second'
import '../../styles/gbs.css'
import Foot from './Foot'
import Nav from './Nav'

export default function Gbs(props) {

    useEffect(() => {
        lazyLoad()
    }, [])

    function lazyLoad() {
        console.log('Observing')
        let paragraphs = document.querySelectorAll('.lazy');
	
		const options = {
			root: null,
			treshold: 1,
			rootMargin: "-150px"
		}
	
		let observer = new IntersectionObserver(function
		(entries, obs) {
			entries.forEach(entry => {
				if(!entry.isIntersecting) {
					return;
				}
				entry.target.classList.add('opac');
			})
		}, options);
	
		paragraphs.forEach(item => {
			observer.observe(item);
		});
    }

    return (
        <div className="main">
            <Nav />
            <section className="main_top">
                <h1>Gobbas IT</h1>
                <p>IT och Elarbeten från samma ställe</p>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 319"><path fill="#f9f9f7" fillOpacity="1" d="M0,224L48,208C96,192,192,160,288,170.7C384,181,480,235,576,234.7C672,235,768,181,864,181.3C960,181,1056,235,1152,218.7C1248,203,1344,117,1392,74.7L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
            </section>
            <Wavy />
            <Second />
            <Foot />
        </div>
    )
}