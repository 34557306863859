import React from "react"
import '../styles/button.css'
    
export default function Button (props) {

    function handleFunc() {
        if (props.loading) return
        props.clickButton()
    }

    if (props.mode === "submit") {
        return (
            <div className="button">
                <input type="submit" className="button-style" disabled={props.disabled} form={props.form} value={props.children} />
                {props.loading ? <div className="loading" /> : null}
            </div>
        )
    }

    return (
        <div className="button">
            <button 
                className={props.loading ? "button-style button-loading" : "button-style"}
                form={props.form}
                type={props.mode || ""}
                onClick={handleFunc}
                disabled={props.disabled}
            >
                {props.children}
            </button>
            {props.loading ? <div className="loading" /> : null}
        </div>
    )
}