import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setDate, setEuro } from '../../utility'
import { actions, modals } from '../../constants'

import '../../styles/lines.css'


export default function Lines({ data }) {

    const [total, setTotal] = useState(0)
    const [totalVat, setTotalVat] = useState(0)
    const [totalInvent, setTotalInvent] = useState(0)
    const [totalOther, setTotalOther] = useState(0)
    const [services, setTotalServices] = useState(0)
    const [vat, setVat] = useState(0)
    const [salary, setSalary] = useState(0)
    const [biSalary, setBiSalary] = useState(0)
    const [dailys, setDailys] = useState(0)

    const [result, setResult] = useState(0)
    const [vatResult, setVatResult] = useState(0)

    const dispatch = useDispatch()
        
    useEffect(() => {
        setTotal(data.filter(i => i.type === 1).reduce((acc, item) => { return acc + item.sum }, 0));
        setTotalVat(data.filter(i => i.type === 1).reduce((acc, item) => { return acc + item.vat }, 0));
        setTotalInvent(data.reduce((acc, item) => { return acc + Number(item.booking.invent) }, 0));
        setTotalOther(data.reduce((acc, item) => { return acc + Number(item.booking.other) }, 0));
        setTotalServices(data.reduce((acc, item) => { return acc + Number(item.booking.services) }, 0))
        setVat(data.filter(i => i.type !== 1 && i.type !== 4).reduce((acc, item) => { return acc + item.vat }, 0));
        setSalary(data.reduce((acc, item) => { return acc + Number(item.booking.personel) }, 0));
        setBiSalary(data.reduce((acc, item) => { return acc + Number(item.booking.biPersonel) }, 0));
        setDailys(data.filter(i => i.type === 4).reduce((acc, item) => { return acc + item.daily }, 0));
    }, [data])

    useEffect(() => {
        setResult(total - (totalInvent + totalOther + salary + biSalary))
    }, [total, totalInvent, totalOther, salary, biSalary])

    useEffect(() => {
        setVatResult(vat - totalVat)
    }, [vat, totalVat])

    return (
        <div className="data_wrapper">
            <div className="dataTable">
                <table>
                    <thead>
                        <tr>
                            <th>#</th><th>Händelse</th><th>Mottagare</th><th>Datum</th><th>Netto</th><th>Moms</th><th>Fil</th><th align="center">Ändra</th><th>Radera</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, i) => {
                            return (
                                <tr key={item.id}>
                                    <td>{i + 1}</td>
                                    <td>{item.type === 1  ? "Faktura" : item.type === 4 ? "Lönebetalning" : item.text}</td>
                                    <td>{item.to}</td>
                                    <td>{setDate(item.date)}</td>
                                    <td>{setEuro(item.sum)}</td>
                                    <td>{setEuro(item.vat)}</td>
                                    <td><a href={item.file} target="_blank" rel="noreferrer">&#10148;</a></td>
                                    <td align="center"><span className="editMeBtn" onClick={() => dispatch({type: actions.TOGGLE_MODAL, name: modals.EDIT_ITEM, parameters: { ...item }})}><i className="fal fa-pencil"></i></span></td>
                                    <td align="center"><span className="deleteItem" onClick={() => dispatch({ type: actions.TOGGLE_MODAL, name: modals.DELETE_ITEM, parameters: { ...item } })}>&#10006;</span></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className="stats">
                <table>
                    <tbody>
                        <tr>
                            <td style={{textAlign: 'right'}}>Omsättning:</td>
                            <td>{setEuro(total)}</td>
                            <td style={{textAlign: 'right'}}>Moms i omsättning:</td>
                            <td className="wall">{setEuro(totalVat)}</td>
                            <td  style={{textAlign: 'right'}}>Resultat:</td>
                            <td className={result < 0 ? "red" : "green"}>{setEuro(result)}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'right'}}>Inventarier:</td>
                            <td>{setEuro(totalInvent)}</td>
                            <td style={{textAlign: 'right'}}>Köpta tjänster:</td>
                            <td className="wall">{setEuro(services)}</td>
                            <td style={{textAlign: 'right'}}>Momsbalans:</td>
                            <td className={vatResult < 0 ? "red" : "green"}>{setEuro(vatResult)}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'right'}}>Övrigt:</td>
                            <td>{setEuro(totalOther)}</td>
                            <td style={{textAlign: 'right'}}>Moms:</td>
                            <td className="wall">{setEuro(vat)}</td>
                        </tr>
                        <tr>
                            <td style={{textAlign: 'right'}}>Löner:</td>
                            <td>{setEuro(salary)}</td>
                            <td style={{textAlign: 'right'}}>Dagtraktament:</td>
                            <td className="wall">{setEuro(dailys)}</td>
                            <td style={{textAlign: 'right'}}>Lönebikostnader:</td>
                            <td className="wall">{setEuro(biSalary)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
