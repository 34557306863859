const generateString = (len = 16) => {
    const symbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890?!#%&/()-_+"
    let string = ""
    for (let i = 0; i < Number(len); i++) {
        string += symbols.charAt(Math.floor(Math.random() * symbols.length))
    }
    return string
}

const addZero = (num) => {
    if (num.toString().length === 1) return `0${num}`
    return num
}

export const setDate = (date = new Date()) => {
    let today = new Date(date)
    let day = addZero(today.getDate())
    let month = addZero(today.getMonth() + 1)
    let year = today = today.getFullYear()
    
    return `${day}.${month}.${year}`
}

export const dateOutput = (date = new Date()) => {
    let today = new Date(date)
    let day = addZero(today.getDate())
    let month = addZero(today.getMonth() + 1)
    let year = today = today.getFullYear()
    
    return `${year}-${month}-${day}`
}

export const setEuro = (sum) => {
    return `${(sum / 100).toFixed(2)} €`
}

export default generateString