import React from 'react';


export default function TripItem(props) {

    const date = `${new Date(props.date).getDate()}.${new Date(props.date).getMonth() + 1}.${new Date(props.date).getFullYear()}`

    return (
        <div className="location_wrapper" onClick={() => props.toggleMyTrip()} onMouseOver={() => props.hoverTrip()} onMouseOut={() => props.endHoverTrip()}>
            <h3>{props.name}</h3>

            {props.type === "large" ? 
            <>
                <p className="date">{date}</p>
                <p className="days">{props.duration} {props.days === 1 ? 'dag' : 'dagar'}</p>
                <p className="days">{props.stops} besökta platser</p>
            </> : null
        }
        </div>
    )
}