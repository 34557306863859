import React from 'react'
import '../../styles/gbs.css'

export default function Wavy() {
    return (
        <section className="wavy_second">
            <div className="design_part t_white filled rounded">
                <h1 className="task">IT Tjänster</h1>
                <i className="fal fa-phone-laptop design_icon"></i>
            </div>
            <div className="design_part t_blue">
                <p className="lazy">Anskaffningar</p>
                <p className="lazy">Reparationer</p>
                <p className="lazy">Guidning och utbildningar</p>
            </div>
        </section>
    )
}
