import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { actions, modals } from '../../constants'
import { db } from '../../firebase/firebase'

import Button from "../Button"

const DeleteLine = () => {
    const state = useSelector((state) => {
        const { name, modalData} = state.modal

        return { name, modalData }
    })
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [check, setCheck] = useState("")
    const [allowed, setAllowed] = useState(false)
    
    const deleteDocument = () => {
        setLoading(true)
        db.collection('lines').doc(state.modalData.id).delete()

        setTimeout(() => {
            setLoading(false)
            dispatch({ type:actions.TOGGLE_MODAL })
        }, 1500);
    }

    useEffect(() => {
        if (check === state.modalData?.identifier) setAllowed(true)
        else setAllowed(false)
    }, [check])

    useEffect(() => {
        setCheck("")
        setAllowed(false)
    }, [])

    if (state.name !== modals.DELETE_ITEM) return null

    return (
        <div className="backdrop">
            <div className="deleteModal">
                <h1 className="red">Radera händelse</h1><br />
                <table>
                    <tbody>
                        <tr><td><strong>Identifierare:</strong></td><td>{state.modalData.identifier}</td></tr>
                        <tr><td><strong>Till:</strong></td><td>{state.modalData.to}</td></tr>
                        <tr><td><strong>Info:</strong></td><td>{state.modalData.type === 1 ? "Faktura" : state.modalData.text}</td></tr>
                        <tr><td><strong>Bokföringsår:</strong></td><td>{state.modalData.year}</td></tr>
                        <tr><td><strong>Summa:</strong></td><td>{(state.modalData.sum / 100).toFixed(2)} €</td></tr>
                    </tbody>
                </table>
                <div className="deleteInfo">
                    <p>För att radera, skriv in dokumentets identifierare</p>
                    <input type="text" placeholder={state.modalData.identifier} value={check} onChange={(e) => setCheck(e.target.value)}/>
                </div>
                <div className="row">
                    <Button disabled={!allowed} loading={loading} clickButton={deleteDocument}>Radera</Button>
                    <Button clickButton={() => dispatch({ type:actions.TOGGLE_MODAL })}>Stäng</Button>
                </div>
            </div>
        </div>
    )
}


export default DeleteLine