import React, { useState } from "react"
import '../styles/login.css'
import { Route, Redirect } from "react-router-dom"
import { useAuth } from '../contexts/AuthContext'
import { useHistory } from "react-router"
import Alert from './Alert'
import logo from '../images/logo512.png'


export default function Login({children, ...rest}){
    
    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')
    let [btn, setBtn] = useState(false)
    let [error, setError] = useState('')
    const { login, currentUser } = useAuth()
    const history = useHistory()

    async function loginUser(e) {
        e.preventDefault()

        try {
            setBtn(!btn)
            setError('')
            await login(user, password)

            //If login is successful
            history.push('/myjourney')
        } catch {
            setError('Fel i användarnamn eller lösenord.')
            setBtn(false)
        }
    }

    return ( 
        <Route
            {...rest}
            render={props => {
                return currentUser ? <Redirect to="/myjourney" /> : 
                    <div className="login-image">
                        <div className="login-wrapper">
                            <div className="login">
                                <h2>Logga in</h2>
                                {error ? <Alert>{error}</Alert> : null}
                                <img src={logo} alt="Zigenarpasset" />
                                <form onSubmit={loginUser}>
                                    <div className="form-wrapper">
                                        <span>Username</span>
                                        <input type="text" aria-label="user" onChange={(e) => setUser(e.target.value)} required/>
                                    </div>
                                    <div className="form-wrapper">
                                        <span>Password</span>
                                        <input type="password" aria-label="password" onChange={(e) => setPassword(e.target.value)} required/>
                                    </div>
                                    <div className="button">
                                        <button className="button-style"
                                            disabled={btn}
                                        >Login</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                }
            }
        >
        </Route>
     );
};