import React from 'react'
import '../../styles/gbs.css'

export default function Second() {
    return (
        <section className="section_two">
            <div className="design_part t_blue">
                <p className="lazy">Installationer</p>
                <p className="lazy">Reparationer</p>
                <p className="lazy">Övriga elarbeten</p>
            </div>
            <div className="design_part t_white filled rounded">
                <h1 className="task">Eltjänster</h1>
                <i className="fal fa-bolt design_icon"></i>
            </div>
        </section>
    )
}
