import React, { useState } from 'react'
import '../../styles/myjourney.css'
import { MapContainer, TileLayer } from 'react-leaflet'
import { Transition, animated } from 'react-spring'
import LocationMarker from './LocationMarker'
import NewLocation from './NewLocation'
import LocationModal from './LocationModal'
import TripModal from './TripModal'
import NewRoute from './NewRoute'
import PlacesView from './PlacesView'
import TripsView from './TripsView'
import MyLocation from './MyLocations'
import { useAuth } from '../../contexts/AuthContext'
import { useHistory } from "react-router"
import Firestore from '../../firebase/firestore'


export default function Myjourney (props) {

    let [coords, setCoords] = useState([61.9241, 25.7482])
    let [selected, setSelected] = useState('places')
    let [viewType, setViewType] = useState('small')
    let [viewTrip, setViewTrip] = useState([])
    let [location, setLocation] = useState({})
    let [trip, setTrip] = useState({})
    const places = Firestore('places')
    const trips = Firestore('trips')
    const shares = Firestore('shares')
    const sharedPlaces = Firestore('places', 'share', 'array-contains-any')
    const allPlaces = places.concat(sharedPlaces)
    let history = useHistory()
    const { currentUser, logout } = useAuth()



    function updatePosition(event) {
        setCoords(event)
    }

    async function handleLogout(){
        await logout()
        history.push('/')
    }
    

    return (
        <>
            <div className="journey_wrapper">
                <div className="jouney_left">
                    <div className="journey_control_top">
                        <div className="profile">
                            <div className="user">
                                <i className="fal fa-user-cowboy"></i>
                                <span>{currentUser?.email}</span>
                            </div>
                            <div className="logout" onClick={handleLogout}>
                                <i className="fal fa-sign-out-alt"></i>
                            </div>
                        </div>
                        <p className="top_coords">
                            Kordinater
                            <span className="coords">[{coords[0].toFixed(4)}, {coords[1].toFixed(4)}]</span>
                        </p>
                        {selected === 'places' ? 
                            <button className=" button-style btn_wide" onClick={() => setLocation({new: true})}>Ny plats</button> :
                            <button className="button-style btn_wide" onClick={() => setTrip({new: true})}>Ny resa</button>
                        }
                        <div className="object_view">
                            <div className={viewType === 'large' ? "viewButton activeView" : "viewButton"} onClick={() => setViewType('large')} title="Detaljerad vy"><i className="fal fa-square"></i></div>
                            <div className={viewType === 'small' ? "viewButton activeView" : "viewButton"} onClick={() => setViewType('small')} title="Basvy"><i className="fal fa-rectangle-wide"></i></div>
                        </div>
                        <div className="selection">
                            <div className={selected === 'places' ? "divider selected" : "divider"} onClick={() => setSelected('places')}>Campingplatser</div>
                            <div className={selected === 'routes' ? "divider selected" : "divider"} onClick={() => setSelected('routes')}>Resor</div>
                        </div>
                    </div>
                    <div className="journey_control_bottom">
                        <div className="mylocations">
                            {selected === 'places' ?
                                <PlacesView 
                                    data={allPlaces.sort((a, b) => b.name - a.name)}
                                    type={viewType}
                                    toggleMyLocation={(data) => setLocation({view: true, data})}
                                />
                            :
                                <TripsView
                                    data={trips}
                                    type={viewType}
                                    toggleMyTrip={(data) => setTrip({view: true, data})}
                                    hoverTrip={(e) => setViewTrip(e)}
                                    endHoverTrip={() => setViewTrip([])}
                                />
                            }
                        </div>
                    </div>
                </div>

                <div className="journey_right" id="map">
                    <MapContainer center={coords} zoom={6} scrollWheelZoom={false}>
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <LocationMarker updatePosition={updatePosition}/>
                        {places.map(item => {
                            return <MyLocation 
                                key={item.id}
                                id={item.id}
                                name={item.name}
                                position={item.coords}
                                toggleLocation={() => setLocation({view: true, data: item})}
                                viewMe={viewTrip}
                                mode={selected}
                            />
                        })}
                    </MapContainer>
                </div>
            </div>

            {location.new ? 
                <div className="backdrop">
                    <Transition
                        items={location.new}
                        from={{ opacity: 0, marginTop: -500 }}
                        enter={{ opacity: 1, marginTop: 0 }}
                        leave={{ opacity: 0, marginTop: 500 }}
                        revers={location.new}
                    >
                        {(styles, item) => item &&
                        <animated.div className="my-modal" style={styles}>
                            <NewLocation
                                coords={coords}
                                shares={shares[0]}
                                closeModal={() => setLocation({})}
                            />
                        </animated.div>
                        }
                    </Transition>
                </div>
            : null
            }

            {location.view ?
                <div className="backdrop">
                    <Transition
                    items={location.view}
                    from={{ opacity: 0, marginTop: -500 }}
                    enter={{ opacity: 1, marginTop: 0 }}
                    leave={{ opacity: 0, marginTop: 500 }}
                    revers={location.view}
                    >
                    {(styles, item) => item &&
                        <animated.div className="location_modal" style={styles}>
                        <LocationModal 
                            data={location.data}
                            closeLocationModal={() => setLocation({})}
                        />
                        </animated.div>
                    }
                    </Transition>
                </div>
                : null
            }

            {trip.new ? 
                <div className="backdrop">
                    <Transition
                        items={trip.new}
                        from={{ opacity: 0, marginTop: -500 }}
                        enter={{ opacity: 1, marginTop: 0 }}
                        leave={{ opacity: 0, marginTop: 500 }}
                        revers={trip.new}
                    >
                        {(styles, item) => item &&
                        <animated.div className="my-modal" style={styles}>
                            <NewRoute
                                closeModal={() =>setTrip({})}
                                places={places}
                            />
                        </animated.div>
                        }
                    </Transition>
                </div>
            : null
            }
            {trip.view ? 
                <div className="backdrop">
                    <Transition
                        items={trip.view}
                        from={{ opacity: 0, marginTop: -500 }}
                        enter={{ opacity: 1, marginTop: 0 }}
                        leave={{ opacity: 0, marginTop: 500 }}
                        reverse={trip.view}
                    >
                        {(styles, item) => item && 
                            <animated.div className="trips-modal" style={styles}>
                                <TripModal 
                                    places={places}
                                    data={trip.data}
                                    images={trip.data.stops}
                                    closeModal={() => setTrip({})}
                                />
                            </animated.div>
                        }
                    </Transition>
                </div>
            : null
            }
        </>
    )
}