import { actions } from '../constants'
const INITIAL_STATE = {
    lines: [],
}

const linesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actions.SET_LINES:
            return {
                lines: [...action.parameters]
            }
        
        default: return { ...state }
    }
}

export default linesReducer