import React from 'react';


export default function LocationModal(props) {

    let { data } = props;
    let date = `${new Date(data.indate).getDate()}.${new Date(data.indate).getMonth() + 1}.${new Date(data.indate).getFullYear()}`


    return (
        <React.Fragment>
            <div className="head" style={{backgroundImage: `url(${data.pic})`}}>
                <h1>
                    {data.name}
                    <p>[{data.coords[0].toFixed(4)},{data.coords[1].toFixed(4)}]</p>
                </h1>
            </div>
            <div className="location_info">
                <div className="data_float">
                    <h3><strong>Adress: </strong>{data.address}</h3>
                    <h4><strong>Besök:</strong> {date}, {data.days} {data.days === 1 ? 'natt' : 'nätter'}</h4>
                    <h4><strong>Kostnad per natt:</strong> {(data.sum / (data.days * 100)).toFixed(2).replace('.', ',')} €</h4><br />
                    <p className="stars sp-left"><strong>Solar: </strong>
                    {[...Array(data.score)].map((item, i) => {
                        return <span className="start" key={i}>&#9788;</span>
                    })} ({data.score}/10)
                    </p>
                </div>
                <div className="data_float t_center">
                    {data.album ? 
                    <a href={data.album} className="google_album_buton" target="_blank" rel="noreferrer">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/Google_Photos_icon_%282020%29.svg/1024px-Google_Photos_icon_%282020%29.svg.png" alt="Open up gallery" />
                        <span>Gå till album</span>
                    </a>
                    : null }
                </div>
                <div className="display_quill" dangerouslySetInnerHTML={{__html: data.text}} />
                <button className="button-style" onClick={() => props.closeLocationModal()}>Stäng</button>
            </div>
        </React.Fragment>
    )
}