import React from 'react'
import LocationItem from './LocationItem'

export default function PlacesView(props) {
    const { data, type } = props

    return (
        <>
            {!data.length ? <><br /><p>Klicka på {type === 'places' ? '"Ny plats"' : '"Ny resa"'} för att skapa nytt minne.</p></> : data.map(item => {
                return <LocationItem 
                    key={item.id}
                    name={item.name}
                    date={item.indate}
                    days={item.days}
                    score={item.score}
                    toggleMyLocation={() => props.toggleMyLocation(item)}
                    type={type}
                />
            })}
        </>
    )
}
