import { useState, useEffect } from "react"
import { useAuth } from "../contexts/AuthContext"
import { db } from './firebase'

const Firestore = (collection, key = 'userId', type = '==') => {
    const [data, setData] = useState([])
    const { currentUser } = useAuth()
    let searchUser = currentUser.uid

    if(type === "in" || type === "array-contains-any") searchUser = [currentUser.uid]

    useEffect(() => {

        const unsubscribe = 
        db.collection(collection).where(`${key}`, `${type}`, searchUser)
        .onSnapshot((snap) => {
            let documents = []
            snap.forEach(item => {
                let itemdata = item.data()
                let location = {
                    id: item.id,
                    ...itemdata
                }
                documents.push(location)
            })

            setData(documents)
        })


        return () => unsubscribe()
        // eslint-disable-next-line
    }, [])


    return data
}


export default Firestore