import React, { useState } from 'react'
import Quill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useAuth } from '../../contexts/AuthContext'
import { db } from '../../firebase/firebase'


export default function NewLocation(props) {

    let [name, setName] = useState("")
    let [address, setAddres] = useState("")
    let [indate, setIndate] = useState(null)
    let [days, setDays] = useState(null)
    let [sum, setSum] = useState(null)
    let [pic, setPic] = useState("")
    let [album, setAlbum] = useState("")
    let [text, setText] = useState("")
    let [score, setScore] = useState(1)
    let [btnDisabled, disableBtn] = useState(false)
    let [share, setShare] = useState([])
    
    let { coords, shares } = props;
    let { currentUser } = useAuth()

    async function formHandle(e) {
        e.preventDefault()
        try {
            disableBtn(true)
            let data = {
                userId: currentUser.uid,
                coords, name, address, indate, pic, text, album,share,
                days: parseInt(days),
                score: parseInt(score),
                sum: parseInt(sum) * 100,
                created: new Date()
            }
            
            await db.collection('places').add(data)
    
            setTimeout(() => {
                document.getElementById('newlocation').reset()
                document.querySelector('.ql-editor').innerHTML = "";
                disableBtn(false)
                clearData()
            }, 1000);
        } catch (error) {
            console.error(error)
            disableBtn(false)
        }
    }

    function clearData(){
        setName("")
        setAddres("")
        setIndate(null)
        setDays(null)
        setSum(null)
        setPic("")
        setAlbum("")
        setText("")
        setScore(1)
        setShare([])

        setTimeout(() => {
            exit()
        }, 500);
    }

    function exit() {
        props.closeModal()
    }

    function updateShares(data) {
        if(!share.includes(data.userId)) return setShare([...share, data.userId]);
        setShare(share.filter(i => i !== data.userId));
    }


    return (
        <>
            <h1>Ny campingplats</h1>
            <form onSubmit={formHandle} id="newlocation">
                <p>Koordinater: <span>[{coords[0].toFixed(4)}, {coords[1].toFixed(4)}]</span></p>
                <div className="input">
                    <span>Campingplats*</span>
                    <input type="text" onChange={(e) => setName(e.target.value)} required/>
                </div>
                <div className="input">
                    <span>Adress*</span>
                    <input type="text" onChange={(e) => setAddres(e.target.value)} required/>
                </div>
                <div className="input">
                    <span>Ankomstdatum*</span>
                    <input type="date" onChange={(e) => setIndate(e.target.value)} required/>
                </div>
                <div className="input">
                    <span>Nätter*</span>
                    <input type="number" setp="1" onChange={(e) => setDays(e.target.value)} required/>
                </div>
                <div className="input">
                    <span>Pris*</span>
                    <input type="number" step=".01" onChange={(e) => setSum(e.target.value)} required/>
                </div>
                <div className="input">
                    <span>Solar* ({score} / 10)</span>
                    <input type="range" min="1" max="10" step="1" value={score} onChange={(e) => setScore(e.target.value)} required/>
                </div>
                <div className="input rows">
                    <span>Dela med användare</span>
                    <div>
                        {shares?.shares.map(share => {
                            return (
                                <p className="shares_row">
                                    <input type="checkbox" onChange={() => updateShares(share)}/>
                                    <span>{share.name}</span>
                                </p>
                            )
                        })}
                    </div>
                </div>
                <div className="input">
                    <span>Länk till Google Photo Album</span>
                    <input type="text" onChange={(e) => setAlbum(e.target.value)}/>
                </div>
                <div className="input">
                    <span>Bildlänk*</span>
                    <input type="text" onChange={(e) => setPic(e.target.value)} required/>
                </div>
                <p><a href="https://www.labnol.org/embed/google/photos/" target="_blank" rel="noreferrer">Skapa bildlänk för Google Photo</a></p>
                <div className="input_quill">
                    <span className="tellme">Berätta om platsen*</span>
                    <Quill
                        theme="snow"
                        onChange={(e) => setText(e)}
                    />
                </div>
                <div className="row">
                    <div className="button">
                        <button className="button-style mr-5" type="submit" disabled={btnDisabled}>Spara plats</button>
                        <button className="button-style" onClick={exit}>Stäng</button>
                    </div>
                </div>
            </form>
        </>
    )
}