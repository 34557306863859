import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth  } from '../../contexts/AuthContext'

export default function PrivateRoute({ children, ...rest}) {

    const { currentUser } = useAuth()

    return (
        <Route
            {...rest}
            render={props => {
                return currentUser ? children : <Redirect to="/login" />
            }}
        >
        </Route>
    )
}
