import React, { useState } from 'react'
import Quill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useAuth } from '../../contexts/AuthContext'
import { db } from '../../firebase/firebase'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'


export default function NewRoute(props) {

    let [name, setName] = useState("")
    let [startDate, setStartDate] = useState(null)
    let [duration, setDuration] = useState(0)
    let [stops, setStops] = useState([])
    let [text, setText] = useState("")
    let [album, setAlbum] = useState("")
    let [btnDisabled, disableBtn] = useState(false)

    const { places } = props
    let { currentUser } = useAuth()

    async function formHandle(e) {
        e.preventDefault()
        try {
            disableBtn(true);
            let data = {
                userId: currentUser.uid,
                name, startDate, duration: parseInt(duration), stops, text, album,
                created: new Date()
            }
            await db.collection('trips').add(data)
    
            setTimeout(() => {
                document.getElementById('newtrip').reset()
                document.querySelector('.ql-editor').innerHTML = "";
                disableBtn(false)
                resetData()
            }, 1500);
        } catch (error) {
            disableBtn(false)
        }
    }

    function resetData(){
        setName("")
        setStartDate(null)
        setDuration(0)
        setStops([])
        setText("")
        setAlbum("")

        exit()
    }

    function toggleMembership(e) {
        let val = e.target.value
        
        if(stops.findIndex(i => i === val) === -1) return setStops([...stops, val])

        setStops(stops.filter(i => i !== val))

    }

    function handleOnDrag(result) {
        if(!result.destination) return
        const items = Array.from(stops)
        const [reorderItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderItem)

        setStops(items)
    }

    function exit() {
        props.closeModal()
    }


    return (
        <React.Fragment>
            <h1>Ny resa</h1>
            <form onSubmit={formHandle} id="newtrip">
                <div className="input">
                    <span>Namnge resan*</span>
                    <input type="text" onChange={(e) => setName(e.target.value)} required/>
                </div>
                <div className="input">
                    <span>Startdatum*</span>
                    <input type="date" onChange={(e) => setStartDate(e.target.value)} required/>
                </div>
                <div className="input">
                    <span>Resans längd i dagar*</span>
                    <input type="number" step="1" min="0" value={duration} onChange={(e) => setDuration(e.target.value)} required/>
                </div>
                <div className="input">
                    <span>Länk till fotoalbum</span>
                    <input type="text" onChange={(e) => setAlbum(e.target.value)}/>
                </div>
                <div className="modal_divider divider_max">
                    <div className="input">
                        <span>Var bodde vi?</span>
                        {places.map(item => {
                            return (
                                <div key={item.id} className="stopItem">
                                    <input type="checkbox" id={item.name} value={item.id} onChange={toggleMembership}/>
                                    <label htmlFor={item.name}>{item.name}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <DragDropContext onDragEnd={handleOnDrag}>
                    <Droppable droppableId="stopItems">
                        {(provided) => (
                            <div className="modal_divider pd-25" {...provided.droppableProps} ref={provided.innerRef}>
                                <p className="route_item_header" style={{color: "#123c69"}}>Sortera platserna enligt resa</p>
                                {!stops.length ? <p>Kryssa in en plats i vänstra kolumnen</p> : null}
                                {stops.map((item, index) => {
                                        return (
                                            <Draggable key={item} draggableId={item} index={index}>
                                                {(provided) => (
                                                    <p className="route_item_draggable" {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                        <span className="num">{index + 1}. </span>
                                                        <span>{places.find(i => i.id === item).name}</span>
                                                    </p>
                                                )}
                                            </Draggable>
                                        )
                                    })
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <div className="input_quill">
                    <span className="tellme">Berätta om resan</span>
                    <Quill
                        theme="snow"
                        onChange={(e) => setText(e)}
                    />
                </div>
                <div className="row">
                    <div className="button">
                        <button className="button-style mr-5" type="submit" disabled={btnDisabled}>Spara resa</button>
                        <button className="button-style" onClick={exit}>Stäng</button>
                    </div>
                </div>
            </form>
        </React.Fragment>
    )
}