import React from 'react'
import '../../styles/gbs.css'

export default function Foot() {
    return (
        <footer>
            <p>&copy; Gobbas IT 2021</p>
            <p>Powered by <a href="https://svelte.dev">SvelteJS</a> & <a href="https://google.firebase.com">Firebase</a></p><br />
            <p><a href="mailto:jens@gobbas.it"> jens@gobbas.it</a></p>
        </footer>
    )
}
