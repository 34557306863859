import React from 'react'
import { Marker, Popup } from 'react-leaflet'


export default function LocationMarker(props) {
    
    const { viewMe, mode } = props

    return (
        <>
        {mode === 'places' || (mode === 'routes' && viewMe.includes(props.id)) ?
            <Marker position={props.position}>
                <Popup>
                    <p>{props.name}</p>
                    <p className="popup_marker" onClick={() => props.toggleLocation()}>Öppna</p>
                </Popup>
            </Marker>
        : null 
        }
        </>
    )
}