import React, { useEffect, useState } from 'react'
import '../../styles/nav.css'
import { Link } from 'react-router-dom'

export default function Nav() {

    const [height, setHeight] = useState(0)
    const [loginHover, setHover] = useState(false)

    useEffect(() => {
        componentScroll()
    }, [])

    function componentScroll() {
        let main = document.querySelector('.main')
        main.addEventListener('scroll', () => {
            let height = main.scrollTop
            setHeight(height)
        })
    }

    return (
        <nav className={height > 3 ? 'nav_filler' : ''}>
            <div className={height > 3 ? "logo_wrapper logo_popper" : "logo_wrapper"}>
                <img className="logo" src="/main_logo.png" alt="Gobbas IT logo" />
            </div>
            <div className="langs">
                <img className="lang" src="/lang_fi.jpg" alt="Suomen lippu" />
                <img className="lang" src="/lang_sv.jpg" alt="Suomen lippu" />
            </div>
            <Link to="/login">
                <div className="login_to_travel" onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                    <div className={loginHover ? "travel_inner login_hovered" : "travel_inner"}>
                        <span>Logga in</span>
                        <img src="https://static.thenounproject.com/png/1223141-200.png" alt="Service login" />
                    </div>
                </div>
            </Link>
        </nav>
    )
}
