import React, { useState } from 'react'
import '../../styles/tripsmodal.css'


export default function TripModal(props) {
    
    const { places, data } = props
    const [toggledPlace, setToggledPlace] = useState('')

    let date = (getdate) => {
        return `${new Date(getdate).getDate()}.${new Date(getdate).getMonth() + 1}.${new Date(getdate).getFullYear()}`
    }

    function handleClick(e) {

        if(e.target.className === "trips-modal--bottom") setToggledPlace('')
    }

    return (
        <>
        <div className="trips-modal--top">
            <h1><span>{data.name}</span>
                <button className="button-style" onClick={() => props.closeModal()}>Stäng</button>
            </h1>
        </div>
        <div className="trips-modal--center">
            <p><span className="blue">&#10095;&#10095;</span> Resan började {date(data.startDate)} och varade {data.duration} {data.duration === 1 ? 'dag' : 'dagar'}.</p>
            <p><span className="blue">&#10095;&#10095;</span> Totalt övernattade vi på {data.stops.length} {data.stops.length === 1 ? 'campingplats' : 'campingplatser'}.</p><br />
            <p className="trip-text" dangerouslySetInnerHTML={{__html: data.text}} >
            </p>
        </div>
        <div className="trips-modal--bottom" onClick={handleClick}>
            {data.stops.map((id, index) => {
                const place = places.find(i => i.id === id)
                return (
                    <div key={id} className={toggledPlace === place.id ? "place toggled-place" : "place"} onClick={() => setToggledPlace(place.id)}>
                        <img src={place.pic} alt="Minne från resa" />
                        <div className="place-info">
                            <h3>{place.name}</h3><hr />
                            <p><strong>Pris per natt:</strong> {(place.sum / (place.days * 100)).toFixed(2)} €</p>
                            <p className="stars sp-left"><strong>Solar: </strong>
                                {[...Array(place.score)].map((item, i) => {
                                    return <span className="start" key={i}>&#9788;</span>
                                })} ({place.score}/10)
                            </p>
                            <p><strong>Övernattning:</strong> {date(place.indate)}</p>
                            <div className="place-normally-hidden" dangerouslySetInnerHTML={{__html: place.text}} />
                        </div>
                        {index !== data.stops.length - 1 ? 
                            <div className="place-arrow">
                                <i className="fal fa-long-arrow-down"></i>
                            </div>
                        : null
                        }
                    </div>
                )
            })}
        </div>
        </>
    )
}
