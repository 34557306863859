import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions, modals, bookingTypes, filters } from '../../constants'
import Firestore from '../../firebase/firestore'

import Lines from './Lines';
import Button from '../Button'
import Newitem from "./Newitem";
import DeleteLine from "./DeleteLine";
import '../../styles/reskontra.css';

export default function Reskontra() {
    const dispatch = useDispatch()
    const lines = Firestore('lines')
    const [allYears, setAllYears] = useState([])
    const [year, setYear] = useState(new Date().getFullYear().toString())
    const [filterLines, setFilterLines] = useState([])
    const [filter, setFilter] = useState([...filters])

    const filterFunction = (e) => {
        if(filter.includes(e.target.value.toString())) setFilter([...filter].filter(item => item !== e.target.value.toString()))
        else setFilter([...filter, e.target.value.toString()])
    }

    //Update filtered lines
    useEffect(() => {
        //If year is set, filter with year and type filter
        if (year) setFilterLines([...lines].filter(line => line.year === year && filter.includes(line.type.toString())))
        //Otherwise, only use type filter
        else setFilterLines([...lines].filter(line => filter.includes(line.type.toString())))
    }, [lines, year, filter])

    //Update available years
    useEffect(() => {
        let years = []
        lines.forEach(yr => {
            if(!years.includes(yr.year.toString())) years = [...years, yr.year]
        })
        setAllYears(years)
    }, [lines])

    return (
        <>
            <div className="reskontra">
                <nav>
                    <Button clickButton={() => dispatch({ type: actions.TOGGLE_MODAL, name: modals.NEW_ITEM })}>
                        <i className="fal fa-paperclip"></i> 
                        <span>Ny rad</span>
                    </Button>
                    <div className="year-selector">
                        <span>Visa år: </span>
                        <select value={year} onChange={(e) => setYear(e.target.value)}>
                            <option value="">Alla</option>
                            {allYears.map(item => {
                                return <option key={item} value={item}>{item}</option>
                            })}
                        </select>
                    </div>
                    <div className="filter">
                        <p>Filtrera händelser</p>
                        <span className="filterAll" onClick={() => setFilter([...filters])}>Alla på</span>
                        {bookingTypes.map(item => {
                            return (
                                <div key={Object.values(item)[0].toString()}>
                                    <input type="checkbox" value={Object.keys(item)[0]} onChange={filterFunction} checked={filter.includes(Object.keys(item)[0])}/>
                                    <span>{Object.values(item)[0]}</span>
                                </div>
                            )
                        })}
                    </div>
                </nav>
                <Lines 
                    data={[...filterLines].reverse()}
                />
            </div>
            <Newitem />
            <DeleteLine />
        </>
    )
}