import { actions } from "../constants"

const INITIAL_STATE = {
    name: undefined,
    modalData: undefined
}

const modalReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case actions.TOGGLE_MODAL:
            return { 
                ...state,
                name: action.name,
                modalData: action.parameters
            }
        
        default: return { ...state }
    }
}

export default modalReducer