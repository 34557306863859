export const actions = {
    TOGGLE_MODAL: "toggleModal",
    SET_LINES: "setLines",
}

export const modals = {
    NEW_ITEM: "newItem",
    DELETE_ITEM: "deleteItem",
    EDIT_ITEM: "editItem",
}

export const bookingTypes = [
    { 1: "Faktura" },
    { 2: "Inventarier" },
    { 3: "Övrigt" },
    { 4: "Lönebetalning" },
]

export const filters = [
    "1", "2", "3", "4"
]