import React, { useState } from 'react'
import { Marker, Popup, useMapEvents } from 'react-leaflet'
import * as L from "leaflet";


export default function LocationMarker(props) {

    const [position, setPosition] = useState([61.9241,  25.7482])

    const LeafIcon = L.Icon.extend({
        options: {}
    });
    const greenIcon = new LeafIcon({
        iconUrl:
            "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|2ecc71&chf=a,s,ee00FFFF",
        iconAnchor: [16, 37]
    });
    
    useMapEvents({
        click: (event) => {
            setPosition([event.latlng.lat, event.latlng.lng]);
            props.updatePosition([event.latlng.lat, event.latlng.lng])
        }
    })
    

    return position === null ? null : (
        <Marker position={position} icon={greenIcon}>
            <Popup>Lägg till camping</Popup>
        </Marker>
    )
}