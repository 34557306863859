import React from 'react';


export default function LocationItem(props) {

    return (
        <div className="location_wrapper" onClick={() => props.toggleMyLocation()}>
            <h3>{props.name}</h3>
            {props.type === "large" ? 
            <>
                <p className="stars">{[...Array(props.score)].map((item, i) => {
                    return <span className="start" key={i}>&#9788;</span>
                })}</p>
                <p className="date">{new Date(props.date).getDate()}.{new Date(props.date).getMonth() + 1}.{new Date(props.date).getFullYear()}</p>
                <p className="days">{props.days} {props.days === 1 ? 'dag' : 'dagar'}</p>
            </> : null
        }
        </div>
    )
}