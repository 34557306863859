import "./App.css";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import store from "./store";

import Login from "./components/Login";
import Reskontra from "./components/reskontra/Reskontra";
import Myjourney from "./components/journey/Myjourney";
import Gbs from "./components/gbs/Main";
import PrivateRoute from "./components/journey/PrivateRoute";

export default function App() {
  return (
    <Router>
      <AuthProvider>
        <Switch>
          <>
            <div className="App">
              <Route exact path="/">
                <Gbs />
              </Route>
              <Provider store={store}>
                <PrivateRoute path="/reskontra">
                  <Reskontra />
                </PrivateRoute>
              </Provider>
              <PrivateRoute path="/myjourney">
                <Myjourney />
              </PrivateRoute>
              <Route exact path="/login">
                <Login />
              </Route>
            </div>
          </>
        </Switch>
      </AuthProvider>
    </Router>
  );
}
