import React from 'react'
import TripItem from './TripItem'

export default function TripsView(props) {
    const { data, type } = props

    return (
        <>
            {!data.length ? <><br /><p>Klicka på "Ny resa" för att skapa nytt minne.</p></> : data.map(item => {
                return <TripItem 
                    key={item.id}
                    name={item.name}
                    date={item.startDate}
                    duration={item.duration}
                    stops={item.stops.length}
                    toggleMyTrip={() => props.toggleMyTrip(item)}
                    type={type}
                    hoverTrip={() => props.hoverTrip(item.stops)}
                    endHoverTrip={() => props.endHoverTrip()}
                />
            })}
        </>
    )
}
