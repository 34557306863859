import React, { useState, useEffect} from 'react'
import { Transition, animated } from 'react-spring'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from '../../constants'
import { db } from '../../firebase/firebase'
import { useAuth } from '../../contexts/AuthContext'
import { modals, bookingTypes } from '../../constants'
import { dateOutput } from '../../utility'

import Button from '../Button'

export default function Newitem() {
    const [btnDisabled, disableBtn] = useState(false)
    const [type, setType] = useState('')
    const [year, setYear] = useState(new Date().getFullYear().toString())
    const [text, setText] = useState('')
    const [identifier, setIdentifier] = useState('')
    const [to, setTo] = useState('')
    const [date, setDate] = useState(dateOutput(new Date()))
    const [file, setFile] = useState('')
    const [sum, setSum] = useState(0)
    const [vat, setVat] = useState(0)
    const [daily, setDaily] = useState(0)

    //Bookings
    const [personel, setPersonel] = useState(0)
    const [biPersonel, setBiPersonel] = useState(0)
    const [invent, setInvent] = useState(0)
    const [services, setServices] = useState(0)
    const [other, setOther] = useState(0)


    let { currentUser } = useAuth()
    
    const dispatch = useDispatch()
    const state = useSelector((state) => {
        const { name, modalData } = state.modal
        
        return { name, modalData }
    })
    
    
    const saveItem = async (e) => {
        try {
            e.preventDefault();
            disableBtn(true)

            let outData = {
                type: Number(type),
                year,
                text,
                identifier,
                to,
                date: dateOutput(date),
                file,
                sum: Number((sum * 100).toFixed(0)),
                vat: Number((vat * 100).toFixed(0)),
                daily: Number((daily * 100).toFixed(0)) ,
                booking: {
                    personel: Number(personel * 100).toFixed(0),
                    biPersonel: Number(biPersonel * 100).toFixed(0),
                    invent: Number(invent * 100).toFixed(0),
                    services: Number(services * 100).toFixed(0),
                    other: Number(other * 100).toFixed(0),
                },
                userId: currentUser.uid,
            }
            
            if(state.name === modals.EDIT_ITEM) db.collection('lines').doc(state.modalData.id).update(outData)
            else db.collection('lines').add(outData)
            
            setTimeout(() => {
                disableBtn(false)
                clearForm()

                //exit form
                if(state.modalData) dispatch({ type: actions.TOGGLE_MODAL })
            }, 1000);
            
        } catch (error) {
            console.error(error)
        }

    }

    const clearForm = () => {
        setType("")
        setYear(new Date().getFullYear().toString())
        setText("")
        setIdentifier("")
        setTo("")
        setDate(dateOutput(new Date()))
        setFile("")
        setSum(0)
        setVat(0)
        setDaily(0)
        setPersonel(0)
        setBiPersonel(0)
        setInvent(0)
        setServices(0)
        setOther(0)
    }

    useEffect(() => {
        if(state.modalData && state.name === modals.EDIT_ITEM) {
            setType(state.modalData.type)
            setYear(state.modalData.year || "")
            setText(state.modalData.text)
            setIdentifier(state.modalData.identifier)
            setTo(state.modalData.to)
            setDate(state.modalData.date)
            setFile(state.modalData.file)
            setSum((state.modalData.sum / 100).toFixed(2))
            setVat((state.modalData.vat / 100).toFixed(2))
            setDaily((state.modalData.daily / 100).toFixed(2))
            setPersonel((state.modalData.booking.personel / 100).toFixed(2))
            setBiPersonel((state.modalData.booking.biPersonel / 100).toFixed(2))
            setInvent((state.modalData.booking.invent / 100).toFixed(2))
            setServices((state.modalData.booking.services / 100).toFixed(2))
            setOther((state.modalData.booking.other / 100).toFixed(2))
        }
    }, [state.modalData]);

    if (state.name !== modals.NEW_ITEM && state.name !== modals.EDIT_ITEM) return null

    return (
        <div className="backdrop">
            <Transition
                items={state.name}
                from={{ opacity: 0, marginTop: -500 }}
                enter={{ opacity: 1, marginTop: 0 }}
                leave={{ opacity: 0, marginTop: 500 }}
                reverse={state.name}
            >
                {(styles, item) =>item  && 
                    <animated.div className="my-modal" style={styles}>
                        <h1>{state.modalData ? 'Redigera objekt' : 'Nytt objekt'}</h1>
                        <form id="addItem" onSubmit={saveItem}>
                            <div className="input">
                                <span>Händelsetyp</span>
                                <select value={type} onChange={(e) => setType(e.target.value)} required>
                                    <option value="">Välj typ</option>
                                    {bookingTypes.map(item => {
                                        return <option key={Object.values(item)[0].toString()} value={Object.keys(item)[0]}>{Object.values(item)[0]}</option>
                                    })}
                                </select>
                            </div>
                            <div className="input">
                                <span>Bokföringsår</span>
                                <input type="text" value={year} onChange={(e) => setYear(e.target.value)} required />
                            </div>
                            {type !== "4" ? 
                                <div className="input">
                                    <span>{type === "1" ? "Fakturanummer" : "Kvittonummer"}</span>
                                    <input type="text" value={identifier} onChange={(e) => setIdentifier(e.target.value)} required/>
                                </div>
                            : null
                            }
                            {type !== '1' && type !== "4" ? 
                                <div className="input">
                                    <span>Händelse</span>
                                    <input type="text" value={text} onChange={(e) => setText(e.target.value)} required />
                                </div>
                            : null}
                            <div className="input">
                                <span>{type === "1" ? "Kund" : type === "4" ? "Löntagare" : "Mottagare"}</span>
                                <input type="text" value={to} onChange={(e) => setTo(e.target.value)} required/>
                            </div>
                            <div className="input">
                                <span>{type === "1" ? "Förfallodag" : type === "4" ? "Betalningsdag" : "Datum"}</span>
                                <input type="date" value={date} onChange={(e) => setDate(e.target.value)} required/>
                            </div>
                            <div className="input">
                                <span>Länk till verifikat</span>
                                <input type="text" value={file} onChange={(e) => setFile(e.target.value)} required/>
                            </div>
                            <div className="input">
                                <span>{type === "4" ? "Nettolön" : "Summa (inkl. moms)"}</span>
                                <input type="number" value={sum} step=".01" onChange={(e) => setSum(e.target.value)} required/>
                            </div>
                            {type !== "4" ?
                                <div className="input">
                                    <span>Moms</span>
                                    <input type="number" value={vat} step=".01" onChange={(e) => setVat(e.target.value)} required/>
                                </div>
                            :null }
                            {type === "4" ?
                                <div className="input">
                                    <span>Traktament</span>
                                    <input type="number" value={daily} step=".01" onChange={(e) => setDaily(e.target.value)} required/>
                                </div>
                            : null}
                            {type !== "1" ?
                                <div className="bookings_wrapper">
                                    <h3>Bokning (inkl. moms)</h3>
                                    <div className="input">
                                        <span>Löner och personalkostnader</span>
                                        <input type="number" value={personel} step=".01" onChange={(e) => setPersonel(e.target.value)} required/>
                                    </div>
                                    <div className="input">
                                        <span>Pensions- och lönebikostnader</span>
                                        <input type="number" value={biPersonel} step=".01" onChange={(e) => setBiPersonel(e.target.value)} required/>
                                    </div>
                                    <div className="input">
                                        <span>Köpta tjänster</span>
                                        <input type="number" value={services} step=".01" onChange={(e) => setServices(e.target.value)} required/>
                                    </div>
                                    <div className="input">
                                        <span>Intentarier & lager</span>
                                        <input type="number" value={invent} step=".01" onChange={(e) => setInvent(e.target.value)} required/>
                                    </div>
                                    <div className="input">
                                        <span>Övrigt avdragbart</span>
                                        <input type="number" value={other} step=".01" onChange={(e) => setOther(e.target.value)} required/>
                                    </div>
                                </div>
                                
                            : null}
                        </form>
                        <div className="row">
                            <div className="button">
                                <Button form="addItem" loading={btnDisabled} disabled={btnDisabled} mode="submit">Spara</Button>
                            </div>
                            <div className="button">
                                <Button clickButton={() => dispatch({ type: actions.TOGGLE_MODAL })}>Stäng</Button>
                            </div>
                        </div>
                        
                    </animated.div>
                }
            </Transition>
        </div>
    )
}
